import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import Icon from '../images/sync-bionics-icon.svg'

import TechnologyParallaxBanner from '../components/TechnologyParallaxBanner'


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Technology" />
    <Container fluid>
      <Row>
        <Col className="zero-padding-col" sm={12} md={6}>
        <Img 
        className="home-header"
        fluid={data.wavepage.childImageSharp.fluid}
        alt="Sync Bionics"
      />
        </Col>
        <Col className="zero-padding-col featured-content-container" sm={12} md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
        <div className="featured-content">
        <h1 className="featured-headline"
          style={{
            fontSize: '85px',
            fontWeight: 'bold' 
          }}
          >
          Wave
        </h1>
        <h2 style={{
          color: '#36762F',
          fontSize: '32px'
        }}>
        Upper Limb Prosthesis Control
        </h2>
        <p>The Wave offers the latest advancements in myoelectric prosthesis control. Currently awaiting FDA approval for clinical testing, the Wave is a small implantable system that records muscle activity from the muscles in the residual limb. These muscle signals are wirelessly transmitted outside the body and used to control the prosthesis.</p>
        <p>Rather than recording signals at the skin surface, the implanted device records direct from the source, producing stronger and cleaner signals, enabling faster and more intuitive prosthesis control.</p>
      </div>
        </Col>
      </Row>
            
    </Container>
    <Container fluid>
      <Row>
      <Col className="zero-padding-col featured-content-container" sm={12} md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: '#36762F',
          }}
        >
        <div className="featured-block-content">
        <h2 style={{
          color: 'white',
          fontSize: '32px'
        }}>
        What is Wave?
        </h2>
        <p className="white-copy">The Wave is an implantable myoelectric system that improvements over traditional systems.</p>
        <ul style={{
          color: 'white'
        }}>
          <li>Intuitive control</li>
          <li>Reliable function</li>
          <li>Simultaneous motions</li>
        </ul>
      </div>
        </Col>
        <Col className="zero-padding-col" sm={12} md={6}>
        <Img 
        className="home-header"
        fluid={data.wave.childImageSharp.fluid}
        alt="Sync Bionics"
      />
        </Col>

      </Row>
            
    </Container>

      <Container fluid>
        <TechnologyParallaxBanner />
 </Container>

 <Container fluid>
    <Row style={{
      paddingLeft: '10%',
      paddingRight: '10%',
      paddingTop: '5%'
    }}>
      <Col sm={6} style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
       <div className="featured-block-content"
        style={{
          marginBottom: '40px'
        }}
       >
        <h2 style={{
          color: '#36762F',
          fontSize: '26px',
        }}>
        Who is a candidate for Wave?
        </h2>
        <p >Wave is for people with upper-limb amputations who want more out of their prosthesis than existing systems can offer. Wave can be used by people with any level of upper-limb amputation and is compatible with most myoelectric limbs.</p>
      </div>
      <div style={{
        width: '100%',
        alignSelf: 'center'
      }}>
      <Img className="girl-arm"
          key="girlImg"
          fluid={data.woman.childImageSharp.fluid}
          alt="Girl up upper limb prosthesis"
        />
        </div>
      </Col>
      <Col sm={6} style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }} >
        <div 
        className="man-img"
        style={{
          width: '60%',
          alignSelf: 'center',
          marginBottom: '40px'
        }}>
<Img 
        className="man"
        key="manImg"
        fluid={data.man.childImageSharp.fluid}
        alt="Man with upper limb prosthesis"
      /> 
        </div>
        <div className="featured-block-content"
        style={{
          marginBottom: '40px'
        }}
       >
        <h2 style={{
          color: '#36762F',
          fontSize: '26px',
        }}>
        How Does Wave Work?
        </h2>
        <p>The Wave is an implantable myoelectric system that improvements over traditional systems.</p>
        <ul>
          <li>Intuitive control</li>
          <li>Reliable function</li>
          <li>Simultaneous motions</li>
        </ul>
      </div>
        
      
      </Col>
    </Row>
 </Container>
  </Layout>
)

export const query = graphql`
  query {
    wavepage: file(relativePath:{eq:"header-wave-page.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wave: file(relativePath:{eq:"022019-MIRA-6-whitebg.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    man: file(relativePath:{eq:"man-upper-limb.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    woman: file(relativePath:{eq:"girl-upper-limb.jpg"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage