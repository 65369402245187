import React from 'react'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax'
import TechParallaxBg from '../images/technology-parallax.jpg'

const TechnologyParallaxBanner = () => (
    <ParallaxProvider>
    <ParallaxBanner
        className="parallax-banner"
        layers={[
            {
                image: `${TechParallaxBg}`,
                amount: -0.1,
                expanded: true,
            }
        ]}
        style={{
            height: '500px',
            backgroundColor: 'rgba(0, 0, 0, 0.32)',
            backgroundBlendMode: 'multiply'

        }}
    >
    </ParallaxBanner>
    </ParallaxProvider>
)

export default TechnologyParallaxBanner